// https://vike.dev/onRenderClient

import { hydrate, render } from 'preact'
import PageShell from './PageShell'

export default async (pageContext) => {
  const { Page, pageProps } = pageContext
  const page = (
    <PageShell pageContext={pageContext}>
      <Page {...pageProps} />
    </PageShell>
  )
  const cont = document.querySelector('body')

  if (pageContext.isHydration) {
    hydrate(page, cont)
  } else {
    render(page, cont)
  }
}
